import React, { useEffect, useRef, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { IoIosArrowUp } from "react-icons/io";
import logo from "../../Assets/Main/logo-navbar.png"; // Ensure the path to your logo is correct
import "../../../src/App.css";
import axios from "axios";


const linkStyle = {
    fontSize: "1.0rem",
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    fontFamily: "Poppins, sans-serif",
    transition: "color 0.3s ease",
};

const hoverStyle = {
    color: "#14aaff",
};

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const sectionRef = useRef(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    } else {
                        entry.target.classList.remove("visible");
                    }
                });
            },
            { threshold: 0.1 }
        );

        const section = sectionRef.current;
        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, []);

    return (
        <div className="faq-item" ref={sectionRef} style={{ marginBottom: "10px" }}>
            <div
                onClick={toggleOpen}
                className={isOpen ? 'open' : ''}
                style={{
                    cursor: "pointer",
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                    border: "1px solid #6169C3",
                    padding: "10px",
                    borderRadius: "5px",
                    background: isOpen ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0)",
                }}
            >
                {question}
            </div>
            {isOpen && (
                <div style={{ marginTop: "5px", color: "#959599", padding: "10px", border: "1px solid #6169C3", borderRadius: "5px", background: "rgba(0,0,0,0)", fontFamily: "Poppins, sans-serif" }}>
                    {answer}
                </div>
            )}
        </div>
    );
};


const Faq = () => {
    const contentRef = useRef(null);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false); // Dodany stan do opóźnienia wyświetlania

    const [guilds, setGuilds] = useState([]); // Pusta tablica na początku

    const [users, setUsers] = useState([]);
    useEffect(() => {
        // Ustawienie tytułu strony na "Nie znaleziono"
        document.title = "GalaxySpeak.pl | FAQ";
        // Opcjonalnie: przywrócenie tytułu po opuszczeniu komponentu
        return () => {
            document.title = "GalaxySpeak.pl"; // lub tytuł domyślny
        };
    }, []); // Pusty array sprawia, że efekt uruchomi się tylko raz

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    } else {
                        entry.target.classList.remove("visible");
                    }
                });
            },
            { threshold: 0.1 }
        );

        const elements = contentRef.current.querySelectorAll(".fade-in");

        elements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            elements.forEach((element) => {
                observer.unobserve(element);
            });
        };
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get("http://localhost:5037/api/counter/last-all-users");
                setUsers(response.data);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);



    useEffect(() => {
        const fetchGuilds1 = async () => {
            try {
                const response = await axios.get("http://localhost:5037/api/counter/last-all-guilds");

                setGuilds(response.data);  // Ustawienie danych niezależnie od ilości elementów
                setTimeout(() => {
                    setIsLoaded(true);
                }, 2000);
            } catch (error) {
                console.error("Error fetching guilds:", error);
            }
        };
        fetchGuilds1();
    }, []);

    const handleScroll = () => {
        setShowScrollButton(window.scrollY > 300);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Container fluid className="about-section" ref={contentRef}>
            <Container>
                <h1 className="fade-in" style={{ color: "#656ac6", fontWeight: "600", fontSize: "1.0rem", marginTop: "30px", fontFamily: "Poppins, sans-serif" }}>
                    FAQ
                </h1>
                <h1 className="fade-in" style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "0 0 10px 10px", fontFamily: "Poppins, sans-serif" }}>
                    CZĘSTO ZADAWANE PYTANIA
                </h1>

                <div className="faq-section fade-in">
                    <h2 style={{ color: "white", fontWeight: "bold", marginTop: "20px", marginBottom: "20px", fontFamily: "Poppins, sans-serif", textAlign: "center" }}>FAQ</h2>
                    <FAQItem
                        question="Jak połączyć się z serwerem TeamSpeak?"
                        answer="Aby połączyć się z naszym serwerem TeamSpeak, pobierz aplikację TeamSpeak, wpisz galaxyspeak.pl w polu adresu serwera, a następnie kliknij przycisk `Połącz`. Prosty sposób, aby dołączyć do naszej społeczności!"
                    />
                    <FAQItem
                        question="Czy boty muzyczne są dostępne na serwerze?"
                        answer="Tak, boty muzyczne są dostępne za darmo! Wystarczy założyć kanał prywatny lub klanowy, a następnie możesz korzystać z botów muzycznych, aby dodatkowo uatrakcyjnić swoje doświadczenie na serwerze."
                    />
                    <FAQItem
                        question="Czy korzystanie z kanałów prywatnych jest płatne?"
                        answer="Nie, kanały prywatne są dostępne za darmo. Możesz swobodnie zakładać i używać kanałów prywatnych bez żadnych dodatkowych opłat."
                    />
                    <FAQItem
                        question="Czy istnieje możliwość dołączenia do administracji serwera?"
                        answer="Rekrutacja do administracji serwera jest otwarta, gdy ogłoszenie w tej sprawie pojawi się na naszym serwerze. Zawsze zachęcamy zainteresowanych do śledzenia ogłoszeń i aplikowania, jeśli spełniacie wymagania."
                    />
                    <FAQItem
                        question="Ile miejsc dostępnych jest na serwerze?"
                        answer="Nasz serwer oferuje aż 256 miejsc, co oznacza, że możesz swobodnie zapraszać swoich znajomych i wspólnie korzystać z naszej platformy."
                    />
                    <FAQItem
                        question="Czy korzystanie z serwera jest płatne?"
                        answer="Nie, korzystanie z serwera jest całkowicie darmowe. Zapewniamy przyjazne środowisko dla społeczności, gdzie każdy może cieszyć się rozmowami i wspólną zabawą bez żadnych opłat."
                    />
                </div>

                <Col className="fade-in">
                    <h1
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "2.0rem",
                            textAlign: "left",
                            fontFamily: "Arial",
                            marginLeft: "-5px",
                            marginTop: "20px"
                        }}
                    >
                        <img
                            src={logo}
                            alt="brand"
                            style={{
                                maxWidth: '100px',
                                height: 'auto',
                                marginRight: '8px',
                                verticalAlign: 'middle',
                            }}
                        />
                        <span className="code">GalaxySpeak.pl</span>
                    </h1>

                    {/* Dodatkowe informacje o serwisie */}
                    <div className="about-info">
                        <p
                            className="home-about-body"
                            style={{
                                color: "#959599",
                                marginRight: "150px",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "400",
                            }}
                        >
                            <span style={{ fontSize: "1.0rem" }}>Budujemy przyszłość komunikacji online</span><br />
                            <span style={{ fontSize: "1.0rem" }}>Innowacyjne rozwiązania dla społeczności</span><br />
                            <span style={{ fontSize: "1.0rem" }}>graczy i entuzjastów TeamSpeak 3</span><br /><br />
                        </p>

                        {/* Links Section */}
                        <div>
                            <p
                                className="home-about-body"
                                style={{
                                    color: "#959599",
                                    marginInline: "-10px",
                                    marginRight: "50px",
                                    marginInlineEnd: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        marginBottom: "-20px",
                                        display: "block",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    WAŻNE LINKI
                                </span><br />

                                {/* Links */}
                                <a href="/" style={hoveredLink === "home" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("home")} onMouseLeave={() => setHoveredLink(null)}>Strona główna</a><br />
                                <a href="https://panel.galaxyspeak.pl/Login" style={hoveredLink === "panel" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("panel")} onMouseLeave={() => setHoveredLink(null)}>Panel</a><br />
                                <a href="/faq" style={hoveredLink === "faq" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("faq")} onMouseLeave={() => setHoveredLink(null)}>FAQ</a><br />
                                <a href="/user-stats" style={hoveredLink === "userstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("userstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Użytkowników</a><br />
                                <a href="/guild-stats" style={hoveredLink === "guildstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("guildstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Gildii</a><br />
                                <a href="https://www.m4code.pl" style={hoveredLink === "creator" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("creator")} onMouseLeave={() => setHoveredLink(null)}>Developer Strony</a><br />

                            </p>
                        </div>

                        <div>
                            <p
                                className="home-about-body"
                                style={{
                                    color: "#959599",
                                    marginRight: "-40px",
                                    marginInline: "-10px",
                                    fontFamily: "Poppins, sans-serif",
                                }}
                            >
                                <span
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        marginBottom: "-20px",
                                        display: "block",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    NOWI UŻYTKOWNICY
                                </span>
                                <br />

                                {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                                {/* Wyświetlanie użytkowników po zakończeniu ładowania */}
                                {isLoaded && users.map((user) => (
                                    <div key={user.id}>
                                        <a
                                            href="https://panel.galaxyspeak.pl/Login"
                                            style={hoveredLink === user.nickname ? { ...linkStyle, ...hoverStyle } : linkStyle}
                                            onMouseEnter={() => setHoveredLink(user.nickname)}
                                            onMouseLeave={() => setHoveredLink(null)}
                                        >
                                            {user.nickname} - {user.date}
                                        </a>
                                        <br />
                                    </div>
                                ))}
                            </p>
                        </div>

                        <div>
                            <p
                                className="home-about-body"
                                style={{
                                    color: "#959599",
                                    marginInline: "-10px",
                                    textAlign: "left",
                                    marginRight: "-200px",
                                    fontFamily: "Poppins, sans-serif",
                                }}
                            >
                                <span
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        marginBottom: "-20px",
                                        display: "block",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    NOWE GILDIE/KLANY
                                </span>
                                <br />

                                {/* Komunikat "Ładowanie..." przed wyświetleniem danych */}
                                {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                                {isLoaded && guilds.slice(0, 4).map(guild => (  // Użycie slice(0, 5), aby ograniczyć do 5 elementów
                                    <div key={guild.id}>
                                        <a
                                            href="https://panel.galaxyspeak.pl/Login"
                                            style={hoveredLink === guild.name ? { ...linkStyle, ...hoverStyle } : linkStyle}
                                            onMouseEnter={() => setHoveredLink(guild.name)}
                                            onMouseLeave={() => setHoveredLink(null)}
                                        >
                                            {guild.name} - {guild.date}
                                        </a>
                                        <br />
                                    </div>
                                ))}
                            </p>
                        </div>
                    </div>
                </Col>
            </Container>

            {showScrollButton && (
                <button
                    onClick={scrollToTop}
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        backgroundColor: "#6169C3",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "20px",
                        cursor: "pointer",
                        zIndex: 1000,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <IoIosArrowUp size={30} />
                </button>
            )}
        </Container>
    );
};

export default Faq;
