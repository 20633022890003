import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row, Form, Button, Card } from "react-bootstrap"; // Import Card for box structure
import { FiSearch } from "react-icons/fi"; // Import the magnifying glass icon
import { IoMdArrowDropright } from "react-icons/io";
import { FaWifi } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import axios from "axios"; // Ensure axios is imported for making API requests



import { IoIosArrowUp } from "react-icons/io";
import "../../../src/App.css";
import logo from "../../Assets/Main/logo-navbar.png";

const linkStyle = {
    fontSize: "1.0rem",
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    fontFamily: "Poppins, sans-serif",
    transition: "color 0.3s ease",
};

const hoverStyle = {
    color: "#14aaff",
};

function About() {
    const contentRef = useRef(null);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const sectionRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [userData, setUserData] = useState(null); // Store user data
    const [userFound, setUserFound] = useState(true); // Check if user is found
    const [loading, setLoading] = useState(false); // Track loading state

    const [isLoaded, setIsLoaded] = useState(false); // Dodany stan do opóźnienia wyświetlania

    const [guilds, setGuilds] = useState([]); // Pusta tablica na początku

    const [users, setUsers] = useState([]);


    useEffect(() => {
        // Ustawienie tytułu strony na "Nie znaleziono"
        document.title = "GalaxySpeak.pl | Wyszukaj Użytkownika";

        // Opcjonalnie: przywrócenie tytułu po opuszczeniu komponentu
        return () => {
            document.title = "GalaxySpeak.pl"; // lub tytuł domyślny
        };
    }, []); // Pusty array sprawia, że efekt uruchomi się tylko raz

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get("http://localhost:5037/api/counter/last-all-users");
                setUsers(response.data);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);



    useEffect(() => {
        const fetchGuilds1 = async () => {
            try {
                const response = await axios.get("http://localhost:5037/api/counter/last-all-guilds");

                setGuilds(response.data);  // Ustawienie danych niezależnie od ilości elementów
                setTimeout(() => {
                    setIsLoaded(true);
                }, 2000);
            } catch (error) {
                console.error("Error fetching guilds:", error);
            }
        };
        fetchGuilds1();
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    function formatTime(seconds) {
        const days = Math.floor(seconds / (24 * 3600));
        seconds %= 24 * 3600;
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);

        // Build the formatted time string
        return `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm' : ''}`.trim();
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        if (!searchQuery) return;

        setLoading(true);
        setUserFound(true);

        try {
            const response = await axios.get("http://localhost:5037/api/search-user", {
                params: { nickname: searchQuery },
            });

            const data = response.data;

            // Format the time fields
            data.timeSpent = formatTime(data.timeSpent);
            data.afkTime = formatTime(data.afkTime);
            data.longestSession = formatTime(data.longestSession);

            setUserData(data);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setUserFound(false);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    } else {
                        entry.target.classList.remove("visible");
                    }
                });
            },
            { threshold: 0.1 }
        );

        const elements = contentRef.current.querySelectorAll(".fade-in");

        elements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            elements.forEach((element) => {
                observer.unobserve(element);
            });
        };
    }, []);


    return (
        <Container fluid className="about-section" ref={contentRef}>
            <Container>
                <h1 className="fade-in" style={{ color: "#656ac6", fontWeight: "600", fontSize: "1.0rem", marginTop: "30px", fontFamily: "Poppins, sans-serif" }}>
                    WYSZUKAJ UŻYTKOWNIKA
                </h1>
                <h1 className="fade-in" style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "0 0 10px 10px", fontFamily: "Poppins, sans-serif" }}>
                    WYSZUKAJ UŻYTKOWNIKA
                </h1>
                <h1
                    style={{
                        color: "#1b8eff",
                        fontSize: "3rem",
                        fontWeight: "400",
                        textAlign: "center",
                        marginLeft: "-10px",
                        padding: 10,
                        fontFamily: "Barlow, sans-serif",
                    }}
                    className="heading fade-in"
                >
                    <span style={{ fontSize: "2.0rem", backgroundImage: "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>
                        <span style={{ fontWeight: 800 }}>Galaxy</span>
                        <span style={{ fontWeight: 800 }}>Speak.pl</span>
                    </span>
                </h1>

                <Row className="mb-4 mt-4 fade-in">
                    {/* User Statistics Box */}
                    <Col md={6}>
                        <Card style={{ padding: "20px", backgroundColor: "#19202b", color: "white", border: "2px solid #656ac6", borderRadius: "10px", width: "100%" }}>
                            {loading ? (
                                <p>Loading...</p> // Show loading message while data is being fetched
                            ) : userFound && userData ? (
                                <>
                                    {/* Header Information */}
                                    <h3 style={{ fontFamily: "Poppins, sans-serif", fontSize: "1.5rem", textTransform: "uppercase", fontWeight: "bold" }}>
                                        Statystyki Użytkownika - {userData.nickname.toUpperCase()}
                                    </h3>

                                    {/* Guild, Join Date, and Status in One Line */}
                                    <div style={{ display: "flex", marginBottom: "2px", textAlign: "left", textTransform: "uppercase" }}>
                                        <p style={{ margin: 0, fontSize: "0.9rem", display: "flex", alignItems: "center", marginRight: "40px", fontWeight: "bold" }}>
                                            <span style={{ color: "#5f64bc", marginRight: "5px", fontSize: "1.2rem", marginTop: "-5px" }}>
                                                <FaTrophy />
                                            </span>
                                            Gildia: {userData.guild}
                                        </p>
                                        <p style={{ margin: 0, fontSize: "0.9rem", display: "flex", alignItems: "center", marginRight: "35px", fontFamily: "Poppins, sans-serif", fontWeight: "bold" }}>
                                            <span style={{ color: "#5f64bc", marginRight: "5px", fontSize: "1.2rem", marginTop: "-5px" }}>
                                                <FaCalendarAlt />
                                            </span>
                                            Dołączył: {userData.joined}
                                        </p>
                                        <p style={{ margin: 0, fontSize: "0.9rem", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                                            <span style={{ color: "#5f64bc", marginRight: "5px", fontSize: "1.2rem", marginTop: "-5px" }}>
                                                <FaWifi />
                                            </span>
                                            {userData.isOnline ? "Online" : "Offline"}
                                        </p>
                                    </div>

                                    <hr style={{ border: "1px solid #656ac6" }} />

                                    {/* Nested Box with Detailed Stats */}
                                    <div style={{ backgroundColor: "#1e2735", borderRadius: "8px", padding: "15px", marginTop: "10px", border: "1px solid #1b8eff", position: "relative" }}>
                                        <h4 style={{ fontFamily: "Barlow, sans-serif", color: "white", fontWeight: "700", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <span style={{ color: "#5f64bc", marginRight: "5px", marginTop: "-5px" }}>
                                                <IoMdArrowDropright />
                                            </span>
                                            <span style={{ textTransform: "uppercase" }}>
                                                Statystyki Użytkownika
                                            </span>
                                        </h4>

                                        {/* Detailed Statistics */}
                                        <div style={{ textAlign: "left", fontFamily: "Poppins, sans-serif", marginBottom: "10px", marginTop: "30px" }}>
                                            <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>Spędzony Czas</span>
                                            <p style={{ color: "#5f64bc", margin: "1px 0 0 0" }}>{userData.timeSpent}</p>
                                        </div>
                                        <div style={{ textAlign: "left", fontFamily: "Poppins, sans-serif", marginBottom: "10px" }}>
                                            <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>Spędzony Czas AFK</span>
                                            <p style={{ color: "#5f64bc", margin: "1px 0 0 0" }}>{userData.afkTime}</p>
                                        </div>
                                        <div style={{ textAlign: "left", fontFamily: "Poppins, sans-serif", marginBottom: "10px" }}>
                                            <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>Najdłuższe Połączenie</span>
                                            <p style={{ color: "#5f64bc", margin: "1px 0 0 0" }}>{userData.longestSession}</p>
                                        </div>

                                    </div>
                                </>
                            ) : (
                                <>
                                    <h4 style={{ color: "white", fontWeight: "bold", fontFamily: "Poppins, sans-serif", marginBottom: "10px", textTransform: "uppercase" }}>
                                        Statystyki Użytkownika
                                    </h4>
                                    <p style={{ color: "#b0b0b0", fontSize: "1.0rem" }}>
                                        Brak wyników dla użytkownika
                                    </p>
                                </>
                            )}
                        </Card>
                    </Col>

                    {/* Search User Box */}
                    <Col md={4} className="ml-auto">
                        <Card style={{ padding: "20px", backgroundColor: "#19202b", color: "white", marginLeft: "auto", maxWidth: "400px", border: "2px solid #656ac6", borderRadius: "10px" }}>
                            <h3 style={{ fontFamily: "Poppins, sans-serif", textTransform: "uppercase", fontWeight: "bold", fontSize: "1.5rem" }}>Wyszukaj Użytkownika</h3>
                            <Form onSubmit={handleSearchSubmit}>
                                <Row>
                                    <Col md={8}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Wpisz nick..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            style={{
                                                fontFamily: "Poppins, sans-serif",
                                                backgroundColor: "#1f2734", // Set your desired background color here
                                                color: "white", // Optional: Set text color to ensure it's readable
                                            }}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Button
                                            type="submit"
                                            style={{
                                                backgroundColor: "#1b8eff",
                                                borderColor: "#1b8eff",
                                                fontFamily: "Poppins, sans-serif",
                                                width: "100%",
                                            }}
                                        >
                                            <FiSearch size={18} />
                                            Szukaj
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Container fluid id="" ref={sectionRef}>
                    <Container>


                        <Col className="fade-in">
                            <h1
                                style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "2.0rem",
                                    textAlign: "left",
                                    fontFamily: "Arial",
                                    marginLeft: "-5px",
                                }}
                            >
                                <img
                                    src={logo}
                                    alt="brand"
                                    style={{
                                        maxWidth: '100px',
                                        height: 'auto',
                                        marginRight: '8px',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                <span className="code">GalaxySpeak.pl</span>
                            </h1>
                        </Col>


                        {/* Dodatkowe informacje o serwisie */}
                        <div className="about-info">
                            <p
                                className="home-about-body fade-in"
                                style={{
                                    color: "#959599",
                                    marginRight: "150px",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "400",
                                }}
                            >
                                <span style={{ fontSize: "1.0rem" }}>Budujemy przyszłość komunikacji online</span><br />
                                <span style={{ fontSize: "1.0rem" }}>Innowacyjne rozwiązania dla społeczności</span><br />
                                <span style={{ fontSize: "1.0rem" }}>graczy i entuzjastów TeamSpeak 3</span><br /><br />
                            </p>

                            {/* Links Section */}
                            <div>
                                <p
                                    className="home-about-body fade-in"
                                    style={{
                                        color: "#959599",
                                        marginInline: "-10px",
                                        marginRight: "50px",
                                        marginInlineEnd: "10px",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "white",
                                            fontWeight: "bold",
                                            marginBottom: "-20px",
                                            display: "block",
                                            fontFamily: "Poppins, sans-serif",
                                        }}
                                    >
                                        WAŻNE LINKI
                                    </span><br />

                                    {/* Links */}
                                    <a href="/" style={hoveredLink === "home" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("home")} onMouseLeave={() => setHoveredLink(null)}>Strona główna</a><br />
                                    <a href="https://panel.galaxyspeak.pl/Login" style={hoveredLink === "panel" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("panel")} onMouseLeave={() => setHoveredLink(null)}>Panel</a><br />
                                    <a href="/faq" style={hoveredLink === "faq" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("faq")} onMouseLeave={() => setHoveredLink(null)}>FAQ</a><br />
                                    <a href="/user-stats" style={hoveredLink === "userstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("userstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Użytkowników</a><br />
                                    <a href="/guild-stats" style={hoveredLink === "guildstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("guildstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Gildii</a><br />
                                    <a href="https://www.m4code.pl" style={hoveredLink === "creator" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("creator")} onMouseLeave={() => setHoveredLink(null)}>Developer Strony</a><br />

                                </p>
                            </div>

                            <div>
                                <p
                                    className="home-about-body fade-in"
                                    style={{
                                        color: "#959599",
                                        marginRight: "-40px",
                                        marginInline: "-10px",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "white",
                                            fontWeight: "bold",
                                            marginBottom: "-20px",
                                            display: "block",
                                            fontFamily: "Poppins, sans-serif",
                                        }}
                                    >
                                        NOWI UŻYTKOWNICY
                                    </span>
                                    <br />

                                    {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                                    {/* Wyświetlanie użytkowników po zakończeniu ładowania */}
                                    {isLoaded && users.map((user) => (
                                        <div key={user.id}>
                                            <a
                                                href="https://panel.galaxyspeak.pl/Login"
                                                style={hoveredLink === user.nickname ? { ...linkStyle, ...hoverStyle } : linkStyle}
                                                onMouseEnter={() => setHoveredLink(user.nickname)}
                                                onMouseLeave={() => setHoveredLink(null)}
                                            >
                                                {user.nickname} - {user.date}
                                            </a>
                                            <br />
                                        </div>
                                    ))}
                                </p>
                            </div>

                            <div>
                                <p
                                    className="home-about-body fade-in"
                                    style={{
                                        color: "#959599",
                                        marginInline: "-10px",
                                        textAlign: "left",
                                        marginRight: "-200px",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "white",
                                            fontWeight: "bold",
                                            marginBottom: "-20px",
                                            display: "block",
                                            fontFamily: "Poppins, sans-serif",
                                        }}
                                    >
                                        NOWE GILDIE/KLANY
                                    </span>
                                    <br />

                                    {/* Komunikat "Ładowanie..." przed wyświetleniem danych */}
                                    {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                                    {isLoaded && guilds.slice(0, 4).map(guild => (  // Użycie slice(0, 5), aby ograniczyć do 5 elementów
                                        <div key={guild.id}>
                                            <a
                                                href="https://panel.galaxyspeak.pl/Login"
                                                style={hoveredLink === guild.name ? { ...linkStyle, ...hoverStyle } : linkStyle}
                                                onMouseEnter={() => setHoveredLink(guild.name)}
                                                onMouseLeave={() => setHoveredLink(null)}
                                            >
                                                {guild.name} - {guild.date}
                                            </a>
                                            <br />
                                        </div>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </Container>
                </Container>
            </Container>

            {showScrollButton && (
                <button
                    onClick={scrollToTop}
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        backgroundColor: "#6169C3",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "20px",
                        cursor: "pointer",
                        zIndex: 1000,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <IoIosArrowUp size={30} />
                </button>
            )}
        </Container>
    );
}

export default About;
