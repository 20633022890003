import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from "../../Assets/Main/tlo.png";
import logo from "../../Assets/Main/logo-duze.png";

function NotFound() {

    useEffect(() => {
        // Ustawienie tytułu strony na "Nie znaleziono"
        document.title = "GalaxySpeak.pl | Błąd 404";

        // Opcjonalnie: przywrócenie tytułu po opuszczeniu komponentu
        return () => {
            document.title = "GalaxySpeak.pl"; // lub tytuł domyślny
        };
    }, []); // Pusty array sprawia, że efekt uruchomi się tylko raz
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f8f9fa',
            color: '#333',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <h1
                style={{
                    color: "#1b8eff",
                    fontSize: "3rem",
                    fontWeight: "400",
                    textAlign: "left",
                    marginLeft: "-40px",
                    padding: 10,
                    fontFamily: "Barlow, sans-serif",
                }}
                className="heading"
            >
                <span
                    style={{
                        fontSize: "2.0rem",
                        backgroundImage:
                            "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}
                >
                    <img
                        src={logo}
                        alt="brand"
                        style={{ maxWidth: '100px', height: 'auto' }}
                    />
                    <span style={{ fontWeight: 800 }}>Galaxy</span>
                    <span style={{ fontWeight: 800 }}>Speak.pl</span>
                </span>
                <span className="wave" role="img" aria-labelledby="wave"></span>
            </h1>
            <h1
                style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "2.5rem",
                    marginBottom: "15px",
                    fontFamily: "Poppins, sans-serif"
                }}
            >
                Żądana strona nie istnieje.
            </h1>
            <h1
                style={{
                    color: "#959599",
                    fontWeight: "bold",
                    fontSize: "1.0rem",
                    marginBottom: "30px",
                    fontFamily: "Poppins, sans-serif"
                }}
            >
                Strona, której szukasz, nie została znaleziona.
            </h1>
            <Link
                to="/"
                className="btn btn-outline-light animated-button"
                style={{ marginLeft: '5px' }} // Ustal wartość marginesu w lewo, np. -10px
            >
                {"Strona główna"}
            </Link>
        </div>
    );
}

export default NotFound;
