import React from 'react';
import "../../../src/App.css";


function HomeCards({ image, position, nickname }) {
    return (
        <div className="project-card-view" style={{ textAlign: 'center', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', transition: 'transform 0.2s' }}>
            <img
                src={image}
                alt={position}
                style={{ width: "150px", height: "150px", borderRadius: '50%', objectFit: 'cover' }} // Ustawiamy wymiary i styl
            />
            <div style={{ marginTop: "10px" }}>
                <h4 style={{ margin: "0", color: "white", fontWeight: "800", fontFamily: "Barlow, sans-serif", }}>{position}</h4>
                <p style={{ margin: "0", color: "#959599" }}>{nickname}</p>
            </div>
        </div>
    );
}

export default HomeCards;