import React from "react";
import Card from "react-bootstrap/Card";
import "../../../src/App.css";
import { FaFacebook } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";

function SocialCards({ platform }) {
    const icon = platform === "facebook" ? <FaFacebook size={50} /> : <FaDiscord size={50} />;

    const platformName = platform === "facebook" ? "FACEBOOK" : "DISCORD";

    return (
        <Card
            className="social-card-view"
            style={{
                width: "180px",
                height: "150px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                border: "2px solid #1b8eff",
                borderRadius: "8px",
                backgroundColor: "#19202b",
                cursor: "pointer",
            }}
        >
            <Card.Body className="social-card-body">
                <div className="social-icon">{icon}</div>
                <h1
                    style={{
                        color: "#656ac6",
                        fontWeight: "600",
                        fontSize: "1.0rem",
                        marginTop: "25px",
                        fontFamily: "Poppins, sans-serif",
                        textAlign: "center",
                    }}
                >
                    {platformName} {/* Wyświetli nazwę platformy */}
                </h1>
            </Card.Body>
        </Card>
    );
}

export default SocialCards;
