import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeCards from "./HomeCards";
import member1 from "../../Assets/Staff/polarny.png";
import member2 from "../../Assets/Staff/dominik.png";
import member3 from "../../Assets/Staff/wybitny.png";
import logo from "../../Assets/Main/logo-navbar.png";
import "../../../src/swiper.css";
import SocialCards from "./SocialCards";
import homeLogo from "../../Assets/Main/img-home.png";
import hometlo from "../../Assets/Main/tlo-home.png"
import bottomIcon from "../../Assets/Main/trophy.png";
import { FaTrophy, FaFolder } from "react-icons/fa";
import axios from "axios";



const linkStyle = {
  fontSize: "1.0rem",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "Poppins, sans-serif",
  transition: "color 0.3s ease",
};

const hoverStyle = {
  color: "#14aaff",
};

function Home2() {
  const sectionRef = useRef(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [todayVisits, setTodayVisits] = useState(0);
  const [RecordOnline, setRecordOnline] = useState(null);
  const [TodayOnline, setTodayOnline] = useState(null);
  const [userCount, setUserCount] = useState(0);

  const onlineCountRef = useRef(null);
  const recordOnlineRef = useRef(null);
  const todayOnlineRef = useRef(null);
  const userCountRef = useRef(null);
  const todayVisitsRef = useRef(null);
  const guildCountRef = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false); // Dodany stan do opóźnienia wyświetlania



  const [guildCount, setGuildCount] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState(0);

  const [guilds, setGuilds] = useState([]); // Pusta tablica na początku

  const [users, setUsers] = useState([]);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("http://57.128.194.84:5037/api/counter/last-all-users");
        setUsers(response.data);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);



  useEffect(() => {
    const fetchGuilds1 = async () => {
      try {
        const response = await axios.get("http://57.128.194.84:5037/api/counter/last-all-guilds");

        setGuilds(response.data);  // Ustawienie danych niezależnie od ilości elementów
        setTimeout(() => {

          setIsLoaded(true);
        }, 2000);
      } catch (error) {
        console.error("Error fetching guilds:", error);
      }
    };
    fetchGuilds1();
  }, []);


  useEffect(() => {
    const fetchGuilds = async () => {
      try {
        const response = await fetch('http://57.128.194.84:5037/api/counter/guilds-rekru'); // Upewnij się, że URL jest poprawny
        if (!response.ok) {
          throw new Error('Failed to fetch guilds');
        }
        const data = await response.json();
        setGuilds(data);
      } catch (error) {
        console.error('Error fetching guilds:', error);
      }
    };

    fetchGuilds();
  }, []);

  const animateValue = (element, start, end, duration) => {
    let startTimestamp = null;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 2);
      element.current.innerText = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  useEffect(() => {
    if (onlineCountRef.current) {
      animateValue(onlineCountRef, 0, onlineUsers, 2000);
    }
    if (recordOnlineRef.current) {
      animateValue(recordOnlineRef, 0, RecordOnline, 2000);
    }
    if (todayOnlineRef.current) {
      animateValue(todayOnlineRef, 0, TodayOnline, 2000);
    }
    if (userCountRef.current) {
      animateValue(userCountRef, 0, userCount, 2000);
    }
    if (todayVisitsRef.current) {
      animateValue(todayVisitsRef, 0, todayVisits, 2000);
    }
    if (guildCountRef.current) {
      animateValue(guildCountRef, 0, guildCount, 2000);
    }
  }, [onlineUsers, RecordOnline, TodayOnline, userCount, todayVisits, guildCount]);

  useEffect(() => {
    const fetchGuildCount = async () => {
      try {
        const response = await axios.get('http://57.128.194.84:5037/api/counter/list_guilds');
        setGuildCount(response.data.guildCount);
      } catch (error) {
        console.error('Error fetching guild count:', error);
      }
    };

    fetchGuildCount();
  }, []);

  useEffect(() => {
    const fetchTodayVisits = async () => {
      try {
        const response = await axios.get("http://57.128.194.84:5037/api/counter/today_visits");
        setTodayVisits(response.data.todayVisits); // Ustawianie pobranej wartości
      } catch (error) {
        console.error("Error fetching today visits:", error);
      }
    };

    fetchTodayVisits(); // Wywołanie funkcji fetch
  }, []); // Pusty array oznacza, że efekt będzie wykonany tylko raz po zamontowaniu komponentu

  useEffect(() => {
    const fetchRecordOnline = async () => {
      try {
        const response = await axios.get("http://57.128.194.84:5037/api/counter/record_online");
        setRecordOnline(response.data.onlineRecord); // Ustawianie pobranej wartości
      } catch (error) {
        console.error("Error fetching today visits:", error);
      }
    };

    fetchRecordOnline(); // Wywołanie funkcji fetch
  }, []); // Pusty array oznacza, że efekt będzie wykonany tylko raz po zamontowaniu komponentu

  // Funkcja do pobierania liczby unikalnych użytkowników z API
  const fetchUserCount = async () => {
    try {
      const response = await fetch('http://57.128.194.84:5037/api/counter/users');
      if (response.ok) {
        const data = await response.json();
        setUserCount(data.userCount); // Ustawienie stanu na wartość z API
      } else {
        console.error('Błąd w pobieraniu liczby użytkowników:', response.status);
      }
    } catch (error) {
      console.error('Błąd w połączeniu z API:', error);
    }
  };

  // Użycie useEffect do pobrania danych przy załadowaniu komponentu
  useEffect(() => {
    fetchUserCount();
  }, []); // Pusty array zależności oznacza, że funkcja uruchomi się tylko raz po załadowaniu komponentu

  useEffect(() => {
    const fetchTodayOnline = async () => {
      try {
        const response = await axios.get("http://57.128.194.84:5037/api/counter/today_online");
        setTodayOnline(response.data.online); // Ustawianie pobranej wartości
      } catch (error) {
        console.error("Error fetching today visits:", error);
      }
    };

    fetchTodayOnline(); // Wywołanie funkcji fetch
  }, []); // Pusty array oznacza, że efekt będzie wykonany tylko raz po zamontowaniu komponentu

  useEffect(() => {
    const fetchOnlineUsers = async () => {
      try {
        const response = await axios.get('http://57.128.194.84:5037/api/counter/online-users');
        setOnlineUsers(response.data.onlineUsers); // Set online users from the response
      } catch (error) {
        console.error('Error fetching online users:', error);
      }
    };

    fetchOnlineUsers(); // Call the function to fetch online users
  }, []); // Empty dependency array means this runs once when the component mounts

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const section = sectionRef.current;
    const elements = section.querySelectorAll(".fade-in");

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <Container fluid className="" id="about" ref={sectionRef}>
      <Container>

        <Row>
          <Col md={8} className="home-about-description fade-in">
            <h1 style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "0 0 10px 10px", fontFamily: "Poppins, sans-serif", textAlign: "left", marginTop: "50px" }}>
              O nas
            </h1>
            <h1 className="heading-name-v2 fade-in">
              <span
                style={{
                  color: "#959599",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  lineHeight: "1.2",
                  marginLeft: "-6px",
                  textAlign: "left"
                }}
              >
                Jesteśmy nowoczesnym serwerem TeamSpeak, który stale rozwija się<br />
                zapewniając stabilnośći wysoką jakość działania.<br />
                Nasz projekt jest tworzony z myślą o graczach i dla graczy.<br />
                Na naszym serwerze znajdziesz zaangażowaną, <br />
                doświadczoną administrację, a także dopracowaną stronę internetową oraz<br />
                rozbudowany panel użytkownika i panel lidera,<br />
                które umożliwiają wygodne zarządzanie kontem i zespołem.
              </span>
              <br />
            </h1>
            <Row style={{ marginTop: "20px", justifyContent: "left", textAlign: "left", marginLeft: "1px" }}>
              <Col xs={12} md={2} style={{ color: "white", marginRight: "20px", fontFamily: "Poppins, sans-serif" }}>
                <h2 ref={onlineCountRef} style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "5px" }}>
                  {onlineUsers} {/* Display the fetched online user count */}
                </h2>
                <p style={{ color: "#959599", fontSize: "0.9rem", whiteSpace: "nowrap" }}>Aktualnie Online</p>
              </Col>
              <Col xs={12} md={2} style={{ color: "white", marginRight: "20px", fontFamily: "Poppins, sans-serif" }}>
                <h2 ref={recordOnlineRef} style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "5px" }}>
                  {RecordOnline}
                </h2>
                <p style={{ color: "#959599", fontSize: "0.9rem", whiteSpace: "nowrap" }}>Rekord Online</p>
              </Col>
              <Col xs={12} md={2} style={{ color: "white", marginRight: "20px", fontFamily: "Poppins, sans-serif" }}>
                <h2 ref={todayOnlineRef} style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "5px" }}>
                  {TodayOnline}
                </h2>
                <p style={{ color: "#959599", fontSize: "0.9rem", whiteSpace: "nowrap" }}>Rekord Online Dzisiaj</p>
              </Col>
              <Col xs={12} md={2} style={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
                <h2 ref={userCountRef} style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "5px" }}>
                  {userCount} {/* Wyświetlenie liczby unikalnych użytkowników */}
                </h2>
                <p style={{ color: "#959599", fontSize: "0.9rem", whiteSpace: "nowrap" }}>
                  Unikalni Użytkownicy
                </p>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px", justifyContent: "left", textAlign: "left", marginLeft: "1px" }}>
              <Col xs={12} md={2} style={{ color: "white", marginRight: "20px", fontFamily: "Poppins, sans-serif" }}>
                <h2 ref={todayVisitsRef} style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "5px" }}>
                  {todayVisits}
                </h2>
                <p style={{ color: "#959599", fontSize: "0.9rem", whiteSpace: "nowrap" }}>Dołączyli Dzisiaj</p>
              </Col>
              <Col xs={12} md={2} style={{ color: "white", marginRight: "20px", fontFamily: "Poppins, sans-serif" }}>
                <h2 ref={guildCountRef} style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "5px" }}>
                  {guildCount}
                </h2>
                <p style={{ color: "#959599", fontSize: "0.9rem", whiteSpace: "nowrap" }}>
                  Założonych Klanów
                </p>
              </Col>
            </Row>
          </Col>

          {/* Kolumna z obrazami */}
          <Col md={4} className="image-container fade-in" style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {/* Pierwsze zdjęcie */}
            <img
              src={hometlo}
              alt="Tło"
              style={{
                maxWidth: "100%",
                height: "auto",
                position: "relative",
                zIndex: 1,
                opacity: 0.8
              }}
            />

            {/* Drugie zdjęcie nałożone na pierwsze */}
            <img
              src={homeLogo}
              alt="Logo na wierzchu"
              style={{
                position: "absolute",
                top: "-5%",
                maxWidth: "100%",
                zIndex: 2
              }}
            />
          </Col>

          {/* Sekcja z napisem i obrazkiem pod zdjęciami */}
          <div className="fade-in" style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "relative",
            zIndex: 1,
          }}>
            <h2 style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "1.2rem",
              fontFamily: "Poppins, sans-serif",
              textAlign: "right",
              marginRight: "50px", // Dodatkowe przesunięcie w prawo dla pierwszego napisu
            }}>
              Statystyki
            </h2>

            <h2 style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "1.0rem",
              fontFamily: "Poppins, sans-serif",
              marginRight: "50px", // Dodatkowe przesunięcie w prawo dla drugiego napisu
            }}>
              Serwera TS3
            </h2>

            {/* Obrazek po prawej stronie za tekstem */}
            <img
              src={bottomIcon} // Ścieżka do obrazka
              alt="Ikona pod zdjęciami"
              style={{
                maxWidth: "50px",
                position: "absolute",  // Ustawienie absolutne dla dokładnej kontroli pozycji
                right: "10px",         // Obrazek wyrównany do prawej
                marginLeft: "10px",    // Dodatkowa odległość od tekstu w poziomie
              }}
            />
          </div>


          <div className="fade-in" style={{ marginTop: '20px', padding: '0 20px' }}>
            <h1 style={{ color: "#656ac6", fontWeight: "600", fontSize: "1.0rem", fontFamily: "Poppins, sans-serif", marginTop: "255px", textAlign: "left" }}>
              REKRUTACJA DO GILDII
            </h1>
            <h1 className="fade-in" style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "0 0 5px 0px", fontFamily: "Poppins, sans-serif", textAlign: "left" }}>
              LISTA GILDII Z REKRUTACJĄ
            </h1>

            <div className="fade-in" style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginTop: '20px',
              gap: '10px', // Ustal odstęp między kartami
            }}>
              {guilds.map((guild, index) => {
                const isHovered = hoveredIndex === index;

                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: '#19202b',
                      borderRadius: '10px',
                      border: "1px solid #5f64bc",
                      padding: '20px',
                      margin: '50px',
                      width: '300px',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                      transition: '0.3s',
                      position: 'relative',
                      fontFamily: "Barlow, sans-serif",
                      textAlign: 'center',
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <h2 style={{
                      color: '#fff',
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      margin: '0 0 10px 0',
                    }}>
                      {guild.name}
                    </h2>

                    {/* Additional Information */}
                    <div style={{
                      marginTop: '15px',
                      fontSize: '0.9rem',
                      transition: 'color 0.3s',
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'default',
                        color: isHovered ? '#acb1bc' : '#acb1bc',
                      }}>
                        <span role="img" aria-label="category" style={{ marginRight: '5px' }}>🔹</span>
                        Kategoria: <strong style={{ color: isHovered ? 'gold' : '#5f64bc', marginLeft: '5px' }}>{guild.type || 'N/A'}</strong>
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'default',
                        color: isHovered ? '#acb1bc' : '#acb1bc',
                        marginBottom: '-2px',
                      }}>
                        <span role="img" aria-label="founder" style={{ marginRight: '5px' }}>
                          <FaTrophy style={{ marginTop: "-4px" }} />
                        </span>
                        Założyciel Gildii: <strong style={{ color: isHovered ? 'gold' : '#5f64bc', marginLeft: '5px' }}>{guild.leader || 'N/A'}</strong>
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'default',
                        color: isHovered ? '#acb1bc' : '#acb1bc',
                      }}>
                        <span role="img" aria-label="founding date" style={{ marginRight: '6px' }}><FaFolder style={{ marginTop: "-4px" }} /></span>
                        Data Założenia: <strong style={{ color: isHovered ? 'gold' : '#5f64bc', marginLeft: '3px' }}>{guild.date || 'N/A'}</strong>
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'default',
                        color: isHovered ? '#acb1bc' : '#acb1bc',
                      }}>
                        <span role="img" aria-label="member count" style={{ marginRight: '5px' }}>🔹</span>
                        Liczba Osób: <strong style={{ color: isHovered ? 'gold' : '#5f64bc', marginLeft: '5px' }}>{guild.guildRecord ? JSON.parse(guild.guildRecord).total : 'N/A'}</strong>
                      </div>
                    </div>

                    {/* Button */}
                    <button style={{
                      backgroundColor: 'transparent',
                      border: "1px solid #5f64bc",
                      color: 'white',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      cursor: 'pointer',
                      position: 'absolute',
                      bottom: '-45px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      transition: 'background-color 0.3s, color 0.3s',
                    }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#5f64bc';
                        e.currentTarget.style.color = 'white';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'white';
                      }}
                      onClick={() => {
                        window.location.href = "ts3server://galaxyspeak.pl"; // Navigate to the guild's link
                      }}
                    >
                      Dołącz
                    </button>
                  </div>
                );
              })}
            </div>
          </div>



        </Row>
        <Row>
          <Col md={12} className="home-about-description fade-in">
            <h1 style={{ color: "#656ac6", fontWeight: "600", fontSize: "1.0rem", fontFamily: "Poppins, sans-serif", marginTop: "255px" }}>
              ZARZĄD
            </h1>
            <h1 style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "0 0 10px 10px", fontFamily: "Poppins, sans-serif" }}> {/* Dodajemy margin do lewej */}
              ZARZĄD SERWERA
            </h1>
            <h1
              style={{
                color: "#1b8eff",
                fontSize: "3rem",
                fontWeight: "400",
                textAlign: "center",
                marginLeft: "-10px",
                padding: 10,
                fontFamily: "Barlow, sans-serif",
              }}
              className="heading"
            >
              <span
                style={{
                  fontSize: "2.0rem",
                  backgroundImage:
                    "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                <span style={{ fontWeight: 800 }}>Galaxy</span>
                <span style={{ fontWeight: 800 }}>Speak.pl</span>
              </span>
              <span className="wave" role="img" aria-labelledby="wave"></span>
            </h1>
          </Col>
        </Row>

        <Container fluid className="fade-in" id="about" ref={sectionRef}>
          <Container>
            {/* Karty usług */}
            <Row style={{ justifyContent: "center", paddingBottom: "10px", marginBottom: "250px" }}>
              <Col md={4} className="project-card fade-in" style={{ margin: "5px" }}>
                <HomeCards
                  image={member1}
                  position="⦁ CEO ⦁"
                  nickname="polarny 🐼"
                />
              </Col>
              <Col md={4} className="project-card fade-in" style={{ margin: "5px" }}>
                <HomeCards
                  image={member2}
                  position="⦁ CEO ⦁"
                  nickname="Dominik. 🦇"
                />
              </Col>
              <Col md={4} className="project-card fade-in" style={{ margin: "5px" }}>
                <HomeCards
                  image={member3}
                  position="⦁ CEO ⦁"
                  nickname="wybitny"
                />
              </Col>
            </Row>


            <Col md={24} className="fade-in">
              <h1 style={{ color: "#656ac6", fontWeight: "600", fontSize: "1.0rem", marginTop: "30px", fontFamily: "Poppins, sans-serif" }}>
                SOCIALMEDIA
              </h1>
              <div style={{ textAlign: "center" }}>
                <h1 style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "10px 10px 10px 10px", fontFamily: "Poppins, sans-serif" }}>
                  SOCIALMEDIA
                </h1>
              </div>
              <h1
                style={{
                  color: "#1b8eff",
                  fontSize: "3rem",
                  fontWeight: "400",
                  textAlign: "center",
                  marginLeft: "-10px",
                  padding: 10,
                  fontFamily: "Barlow, sans-serif",
                }}
                className="heading"
              >
                <span
                  style={{
                    fontSize: "2.0rem",
                    backgroundImage:
                      "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  <span style={{ fontWeight: 800 }}>Galaxy</span>
                  <span style={{ fontWeight: 800 }}>Speak.pl</span>
                </span>
                <span className="wave" role="img" aria-labelledby="wave"></span>
              </h1>

              <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                <Col
                  xs={12}
                  sm={6}
                  md={2}
                  className="project-card fade-in"
                  onClick={() => window.open('https://www.facebook.com/galaxyspeakpl', '_blank')}  // Otwiera w nowej karcie
                  style={{ cursor: 'pointer' }}  // Dodaje kursor wskazujący, że jest to element klikalny
                >
                  <SocialCards platform="facebook" />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={2}
                  className="project-card fade-in"
                  onClick={() => window.open('https://discord.com/invite/naVCpA9Bmg', '_blank')}  // Otwiera w nowej karcie
                  style={{ cursor: 'pointer' }}  // Dodaje kursor wskazujący, że jest to element klikalny
                >
                  <SocialCards platform="discord" />
                </Col>
              </Row>
            </Col>

            <Col className="fade-in">
              <h1
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "2.0rem",
                  textAlign: "left",
                  fontFamily: "Arial",
                  marginLeft: "-5px", // Adjusted margin to bring text and image closer
                }}
              >
                <img
                  src={logo}
                  alt="brand"
                  style={{
                    maxWidth: '100px',
                    height: 'auto',
                    marginRight: '8px', // Add small space to separate the logo from the text if needed
                    verticalAlign: 'middle', // Aligns logo with text vertically
                  }}
                />
                <span className="code">GalaxySpeak.pl</span>
              </h1>

              <div className="about-info">
                <p
                  className="home-about-body fade-in"
                  style={{
                    color: "#959599",
                    marginRight: "150px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                  }}
                >
                  <span style={{ fontSize: "1.0rem" }}>Budujemy przyszłość komunikacji online</span><br />
                  <span style={{ fontSize: "1.0rem" }}>Innowacyjne rozwiązania dla społeczności</span><br />
                  <span style={{ fontSize: "1.0rem" }}>graczy i entuzjastów TeamSpeak 3</span><br /><br />
                </p>

                {/* Links Section */}
                <div>
                  <p
                    className="home-about-body fade-in"
                    style={{
                      color: "#959599",
                      marginInline: "-10px",
                      marginRight: "50px",
                      marginInlineEnd: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginBottom: "-20px",
                        display: "block",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      WAŻNE LINKI
                    </span><br />

                    {/* Links */}
                    <a href="/" style={hoveredLink === "home" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("home")} onMouseLeave={() => setHoveredLink(null)}>Strona główna</a><br />
                    <a href="https://panel.galaxyspeak.pl/Login" style={hoveredLink === "panel" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("panel")} onMouseLeave={() => setHoveredLink(null)}>Panel</a><br />
                    <a href="/faq" style={hoveredLink === "faq" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("faq")} onMouseLeave={() => setHoveredLink(null)}>FAQ</a><br />
                    <a href="/user-stats" style={hoveredLink === "userstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("userstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Użytkowników</a><br />
                    <a href="/guild-stats" style={hoveredLink === "guildstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("guildstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Gildii</a><br />
                    <a href="https://www.m4code.pl" style={hoveredLink === "creator" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("creator")} onMouseLeave={() => setHoveredLink(null)}>Developer Strony</a><br />

                  </p>
                </div>


                <div>
                  <p
                    className="home-about-body fade-in"
                    style={{
                      color: "#959599",
                      marginRight: "-40px",
                      marginInline: "-10px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginBottom: "-20px",
                        display: "block",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      NOWI UŻYTKOWNICY
                    </span>
                    <br />

                    {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                    {/* Wyświetlanie użytkowników po zakończeniu ładowania */}
                    {isLoaded && users.map((user) => (
                      <div key={user.id}>
                        <a
                          href="https://panel.galaxyspeak.pl/Login"
                          style={hoveredLink === user.nickname ? { ...linkStyle, ...hoverStyle } : linkStyle}
                          onMouseEnter={() => setHoveredLink(user.nickname)}
                          onMouseLeave={() => setHoveredLink(null)}
                        >
                          {user.nickname} - {user.date}
                        </a>
                        <br />
                      </div>
                    ))}
                  </p>
                </div>

                <div>
                  <p
                    className="home-about-body"
                    style={{
                      color: "#959599",
                      marginInline: "-10px",
                      textAlign: "left",
                      marginRight: "-200px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginBottom: "-20px",
                        display: "block",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      NOWE GILDIE/KLANY
                    </span>
                    <br />

                    {/* Komunikat "Ładowanie..." przed wyświetleniem danych */}
                    {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                    {isLoaded && guilds.slice(0, 4).map(guild => (  // Użycie slice(0, 5), aby ograniczyć do 5 elementów
                      <div key={guild.id}>
                        <a
                          href="https://panel.galaxyspeak.pl/Login"
                          style={hoveredLink === guild.name ? { ...linkStyle, ...hoverStyle } : linkStyle}
                          onMouseEnter={() => setHoveredLink(guild.name)}
                          onMouseLeave={() => setHoveredLink(null)}
                        >
                          {guild.name} - {guild.date}
                        </a>
                        <br />
                      </div>
                    ))}
                  </p>
                </div>
              </div>
            </Col>
          </Container>
        </Container >
      </Container >
    </Container>
  );
}

export default Home2;
