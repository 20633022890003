import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/Main/logo-duze.png";
import Home2 from "./Home2";
import { IoIosArrowUp } from "react-icons/io";

import "../../../src/App.css";

function Home() {
  const contentRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const [isHoveredButton1, setIsHoveredButton1] = useState(false);
  const [isHoveredButton2, setIsHoveredButton2] = useState(false)
  useEffect(() => {
    // Ustawienie tytułu strony na "Nie znaleziono"
    document.title = "GalaxySpeak.pl | Strona Główna";

    // Opcjonalnie: przywrócenie tytułu po opuszczeniu komponentu
    return () => {
      document.title = "GalaxySpeak.pl"; // lub tytuł domyślny
    };
  }, []); // Pusty array sprawia, że efekt uruchomi się tylko raz

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = contentRef.current.querySelectorAll(".fade-in");

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section>
      <Container fluid className="home-section" id="home" ref={contentRef}>
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header fade-in">
              <h1
                style={{
                  color: "#1b8eff",
                  fontSize: "3rem",
                  fontWeight: "400",
                  textAlign: "left",
                  padding: 10,
                  fontFamily: "Poppins-Medium, sans-serif",
                }}
                className="heading"
              >
                <span
                  style={{
                    fontSize: "1.5rem",
                    backgroundImage:
                      "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  <span style={{ fontWeight: 800 }}>GalaxySpeak.pl</span>
                </span>
                <span className="wave" role="img" aria-labelledby="wave"></span>
              </h1>

              <h1 className="heading-name">
                <span
                  style={{ color: "white", fontWeight: "bold", fontSize: "2rem", fontFamily: "Poppins, sans-serif" }}
                >
                  Rozwijaj klan
                </span>
                <br />
                <span
                  style={{ color: "white", fontWeight: "bold", fontSize: "2rem", fontFamily: "Poppins, sans-serif" }}
                >
                  w sieci z{" "}
                </span>
                <span
                  style={{
                    fontSize: "2rem",
                    fontFamily: "Poppins, sans-serif",
                    backgroundImage:
                      "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  <span style={{ fontWeight: 800 }}>GalaxySpeak.pl</span>
                </span>
                <strong className="main-name"></strong>
              </h1>

              <h1 className="heading-name-v2 fade-in">
                <span
                  style={{
                    color: "#959599",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    lineHeight: "1.2",
                    marginLeft: "10px"
                  }}
                >
                  Nie przegap okazji, aby dołączyć do naszej dynamicznej społeczności Teamspeak 3.<br />
                  Dzięki naszemu doświadczeniu i pasji do wirtualnej komunikacji,<br />
                  możemy wspierać rozwój Twojej interakcji z<br />
                  innymi graczami oraz budowanie silnych więzi w sieci.
                </span>
                <br />
                <div style={{ display: "flex", marginTop: "20px", justifyContent: "left", marginLeft: "-5px", marginBottom: "360px" }}>
                  {/* Button 1 */}
                  <a
                    href="ts3server://galaxyspeak.pl"
                    className="btn btn-outline-light animated-button fade-in"
                    style={{
                      textAlign: "center",
                      marginRight: "10px",
                      border: "1px solid #5f64bc",
                      backgroundColor: isHoveredButton1 ? "#5f64bc" : "transparent",
                      color: isHoveredButton1 ? "white" : "inherit",
                      transition: "background-color 0.3s ease"
                    }}
                    onMouseEnter={() => setIsHoveredButton1(true)}
                    onMouseLeave={() => setIsHoveredButton1(false)}
                  >
                    {"Dołącz do nas"}
                  </a>

                  {/* Button 2 */}
                  <a
                    href="https://panel.galaxyspeak.pl"
                    className="btn btn-outline-light animated-button fade-in"
                    style={{
                      textAlign: "center",
                      border: "1px solid #5f64bc",
                      backgroundColor: isHoveredButton2 ? "#5f64bc" : "transparent",
                      color: isHoveredButton2 ? "white" : "inherit",
                      transition: "background-color 0.3s ease"
                    }}
                    onMouseEnter={() => setIsHoveredButton2(true)}
                    onMouseLeave={() => setIsHoveredButton2(false)}
                  >
                    {"Panel Użytkownika"}
                  </a>
                </div>
              </h1>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid fade-in"
                draggable="false"
                style={{
                  maxHeight: "450px",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                  OUserSelect: "none"
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />

      {showScrollButton && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            background: "#656ac6",
            color: "white",
            border: "none",
            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
            zIndex: 1000,
          }}
        >
          <IoIosArrowUp size={30} />
        </button>
      )}
    </section>
  );
}

export default Home;
