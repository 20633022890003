import React, { useState, useEffect } from "react";
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../Assets/Main/logo-navbar.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Importuj useLocation

function NavBar() {

  const [expand, updateExpanded] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false); // Modal dla języka
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [menuOpen, setMenuOpen] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setVisible(false);
      } else if (currentScrollY < lastScrollY && currentScrollY === 0) {
        setVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollY]);

  const buttonStyle = {
    borderRadius: '25px',
    border: '2px solid #1b8eff',
    backgroundColor: 'transparent',
    color: '#1b8eff',
    fontFamily: "Poppins, sans-serif",
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  const [buttonHover, setButtonHover] = useState({
    returnHover: false,
    discordHover: false,
  });

  const handleReturnHover = () => setButtonHover((prev) => ({ ...prev, returnHover: !prev.returnHover }));
  const handleDiscordHover = () => setButtonHover((prev) => ({ ...prev, discordHover: !prev.discordHover }));

  const collapseStyle = {
    backgroundColor: isMobile ? '#181a27' : 'transparent',
    borderRadius: '10px',
    boxShadow: isMobile ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
    padding: '20px',
    transition: 'background-color 0.3s ease',
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    updateExpanded(false); // Zamknie ogólne rozwijane menu Navbara
  };

  const location = useLocation(); // Uzyskanie lokalizacji
  // Sprawdź, czy obecna lokalizacja to strona Not Found
  const isNotFound = !location.pathname.match(/^(\/|\/faq|\/contact|\/user-stats|\/guild-stats|\/guild-list|\/search-user|\/search-guild|\/viewer)$/); // Warunek sprawdzający

  // Jeśli jesteśmy na stronie Not Found, nic nie renderuj
  if (isNotFound) return null;


  return (
    <>
      <Navbar
        expanded={expand}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          transition: "transform 0.3s ease",
          transform: visible ? "translateY(0)" : "translateY(-100%)",
          backgroundColor: isMobile ? 'white' : 'transparent',
        }}
        expand="md"
        className="navbar"
      >
        <Container>
          <Navbar.Brand href="/" className="d-flex">
            <img src={logo} alt="brand" />
            <h1
              style={{
                color: "#1b8eff",
                fontSize: "3rem",
                fontWeight: "400",
                textAlign: "left",
                marginLeft: "-10px",
                padding: 30,
                fontFamily: "Barlow, sans-serif",
              }}
              className="heading"
            >
              <span
                style={{
                  fontSize: "0.9rem",
                  backgroundImage:
                    "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                <span style={{ fontWeight: 800 }}>Galaxy</span>
                <span style={{ fontWeight: 800 }}>Speak.pl</span>
              </span>
              <span className="wave" role="img" aria-labelledby="wave"></span>
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
            style={{
              backgroundColor: 'white', // Change the background color
              border: 'none', // Remove default border
              color: 'white', // Change the text color (icon color)
              padding: '10px', // Optional: adjust padding
              borderRadius: '5px', // Optional: add border radius
            }}
          >
            <span style={{ backgroundColor: 'inherit' }}></span>
            <span style={{ backgroundColor: 'inherit' }}></span>
            <span style={{ backgroundColor: 'inherit' }}></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" style={collapseStyle}>
            <Nav className="ms-auto" defaultActiveKey="#home">
              <Nav.Item>
                <Nav.Link as={Link} to="/" onClick={() => handleMenuClose()}>
                  STRONA GŁÓWNA
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/faq" onClick={() => handleMenuClose()}>
                  FAQ
                </Nav.Link>
              </Nav.Item>

              <Nav.Item style={{ margin: '0 15px' }}>
                <Dropdown align="end" className="language-dropdown" onToggle={(isOpen) => setMenuOpen(isOpen)}
                  show={menuOpen}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      fontFamily: 'Barlow, sans-serif',
                      fontSize: '16px',
                      fontWeight: '700',
                      color: 'white',
                      padding: '8px 15px',
                      position: 'relative', // Add this line for the animation
                    }}
                  >
                    SERWER
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: 'rgba(25, 26, 38, 0.8)' }}>
                    <Dropdown.Item as={Link} to="/viewer" className="dropdown-item" onClick={() => handleMenuClose()} style={{
                      backgroundColor: 'transparent',
                      color: 'white'
                    }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(25, 26, 38, 0.6)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                      ⦁ PODGLĄD SERWERA
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/search-user" className="dropdown-item" onClick={() => handleMenuClose()} style={{
                      backgroundColor: 'transparent',
                      color: 'white'
                    }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(25, 26, 38, 0.6)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                      ⦁ WYSZUKAJ UŻYTKOWNIKA
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/search-guild" className="dropdown-item" onClick={() => handleMenuClose()} style={{
                      backgroundColor: 'transparent',
                      color: 'white'
                    }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(25, 26, 38, 0.6)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                      ⦁ WYSZUKAJ GILDIE
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/guild-list" className="dropdown-item" onClick={() => handleMenuClose()} style={{
                      backgroundColor: 'transparent',
                      color: 'white'
                    }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(25, 26, 38, 0.6)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                      ⦁ LISTA GILDII
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>

              <Nav.Item style={{ margin: '0 10px' }}>
                <Dropdown align="end" className="language-dropdown">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      fontFamily: 'Barlow, sans-serif',
                      fontSize: '16px',
                      fontWeight: '700',
                      color: 'white',
                      padding: '8px 15px',
                      position: 'relative', // Add this line for the animation
                    }}
                  >
                    STATYSTYKI
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: 'rgba(25, 26, 38, 0.8)' }}>
                    <Dropdown.Item as={Link} to="/user-stats" className="dropdown-item" onClick={() => handleMenuClose()} style={{
                      backgroundColor: 'transparent',
                      color: 'white'
                    }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(25, 26, 38, 0.6)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                      ⦁ UŻYTKOWNIKÓW
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/guild-stats" className="dropdown-item" onClick={() => handleMenuClose()} style={{
                      backgroundColor: 'transparent',
                      color: 'white'
                    }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(25, 26, 38, 0.6)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                      ⦁ GILDII
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>


              <div style={{ marginLeft: '300px' }}></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Modal dla języka */}
      <Modal show={showLanguageModal} onHide={() => setShowLanguageModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%", color: "black" }}>Wybór Języka</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center', color: 'red' }}>
          <p>Zmiana języka jest aktualnie niedostępna.</p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="secondary"
            onClick={() => setShowLanguageModal(false)} // Zmiana na setShowLanguageModal
            onMouseEnter={handleReturnHover}
            onMouseLeave={handleReturnHover}
            style={{
              ...buttonStyle,
              backgroundColor: buttonHover.returnHover ? '#1b8eff' : 'transparent',
              color: buttonHover.returnHover ? 'white' : '#1b8eff',
            }}
          >
            POWRÓT
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header style={{ backgroundColor: "white" }} closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>{('Panel Klienta')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 className="mb-4" style={{ color: "red" }}>{('Panel Klienta jest aktualnie niedostępny!')}</h5>
          <p style={{ color: "red" }}>{('Pracujemy nad panelem, Sprawdź ponownie póżniej!')}</p>
          <ProgressBar now={50} label="50%" className="mt-4" style={{ fontFamily: "Poppins, sans-serif" }} />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
            onMouseEnter={handleReturnHover}
            onMouseLeave={handleReturnHover}
            style={{
              ...buttonStyle,
              backgroundColor: buttonHover.returnHover ? '#1b8eff' : 'transparent',
              color: buttonHover.returnHover ? 'white' : '#1b8eff',
            }}
          >
            {('POWRÓT')}
          </Button>
          <Button
            variant={null} // Remove default Bootstrap styles
            onClick={() => window.location.href = 'https://discord.com/invite/kEbWN4DktJ'}
            onMouseEnter={handleDiscordHover}
            onMouseLeave={handleDiscordHover}
            style={{
              ...buttonStyle,
              backgroundColor: buttonHover.discordHover ? 'transparent' : '#1b8eff',
              color: buttonHover.discordHover ? '#1b8eff' : 'white',
            }}
          >
            {('SERWER DISCORD')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NavBar;
