import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom"; // Importuj useLocation
import logo from "../Assets/Main/logo-m4code.png"; // Upewnij się, że ścieżka do logo jest poprawna

function Footer() {
  const location = useLocation(); // Uzyskanie lokalizacji

  // Sprawdź, czy obecna lokalizacja to strona Not Found
  const isNotFound = !location.pathname.match(/^(\/|\/faq|\/contact|\/viewer|\/user-stats|\/guild-stats|\/guild-list|\/search-user|\/search-guild|\/aboutus)$/); // Warunek sprawdzający

  // Jeśli jesteśmy na stronie Not Found, nic nie renderuj
  if (isNotFound) return null;

  return (
    <Container fluid className="footer">
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        {/* Lewa sekcja */}
        <Col xs={12} md={4} className="footer-copywright" style={{ flex: "1" }}>
          <h3>
            <strong className="footer-name">COPYRIGHT © 2024 - ALL RIGHTS RESERVED BY GALAXYSPEAK.PL</strong>
          </h3>
        </Col>
        {/* Prawa sekcja */}
        <Col xs={12} md={4} className="footer-credits" style={{ textAlign: "center" }}>
          <strong className="footer-name">Strona wykonana przez:</strong>
          <a href="https://m4code.pl" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Logo" style={{ height: "100px" }} /> {/* Dostosuj wysokość w zależności od wymagań */}
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
