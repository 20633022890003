import React, { useEffect, useRef, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { IoIosArrowUp } from "react-icons/io";
import "../../../src/App.css";
import logo from "../../Assets/Main/logo-navbar.png";
import axios from "axios";


const linkStyle = {
  fontSize: "1.0rem",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "Poppins, sans-serif",
  transition: "color 0.3s ease",
};

const hoverStyle = {
  color: "#14aaff",
};

function About() {
  const contentRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false); // Dodany stan do opóźnienia wyświetlania

  const [guilds, setGuilds] = useState([]); // Pusta tablica na początku

  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Ustawienie tytułu strony na "Nie znaleziono"
    document.title = "GalaxySpeak.pl | Podgląd Serwera";

    // Opcjonalnie: przywrócenie tytułu po opuszczeniu komponentu
    return () => {
      document.title = "GalaxySpeak.pl"; // lub tytuł domyślny
    };
  }, []); // Pusty array sprawia, że efekt uruchomi się tylko raz

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("http://localhost:5037/api/counter/last-all-users");
        setUsers(response.data);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);



  useEffect(() => {
    const fetchGuilds1 = async () => {
      try {
        const response = await axios.get("http://localhost:5037/api/counter/last-all-guilds");

        setGuilds(response.data);  // Ustawienie danych niezależnie od ilości elementów
        setTimeout(() => {
          setIsLoaded(true);
        }, 2000);
      } catch (error) {
        console.error("Error fetching guilds:", error);
      }
    };
    fetchGuilds1();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = contentRef.current.querySelectorAll(".fade-in");

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const loadTS3IndexScript = () => {
    const scriptId = "ts3index-script";
    // Check if the script is already loaded
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://ts3index.com/viewer/script.js";
      script.async = true;
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadTS3IndexScript();
  }, []); // This effect runs once when the component mounts

  return (
    <Container fluid className="about-section" ref={contentRef}>
      <Container>
        {/* Header Section */}
        <h1 style={{ color: "#656ac6", fontWeight: "600", fontSize: "1.0rem", marginTop: "30px", fontFamily: "Poppins, sans-serif" }}>
          PODGLĄD
        </h1>
        <h1 style={{ color: "white", fontWeight: "bold", fontSize: "2rem", margin: "0 0 10px 10px", fontFamily: "Poppins, sans-serif" }}>
          PODGLĄD SERWERA
        </h1>
        <h1
          style={{
            color: "#1b8eff",
            fontSize: "3rem",
            fontWeight: "400",
            textAlign: "center",
            marginLeft: "-10px",
            padding: 10,
            fontFamily: "Barlow, sans-serif",
          }}
          className="heading"
        >
          <span
            style={{
              fontSize: "2.0rem",
              backgroundImage:
                "linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            <span style={{ fontWeight: 800 }}>Galaxy</span>
            <span style={{ fontWeight: 800 }}>Speak.pl</span>
          </span>
          <span className="wave" role="img" aria-labelledby="wave"></span>
        </h1>

        {/* Widget TS3index */}
        <div className="ts3index-viewer" data-serverid="303236">
        </div>

        <Col className="fade-in">
          <h1
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "2.0rem",
              textAlign: "left",
              fontFamily: "Arial",
              marginLeft: "-5px",
            }}
          >
            <img
              src={logo}
              alt="brand"
              style={{
                maxWidth: '100px',
                height: 'auto',
                marginRight: '8px',
                verticalAlign: 'middle',
              }}
            />
            <span className="code">GalaxySpeak.pl</span>
          </h1>

          {/* Dodatkowe informacje o serwisie */}
          <div className="about-info">
            <p
              className="home-about-body fade-in"
              style={{
                color: "#959599",
                marginRight: "150px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
              }}
            >
              <span style={{ fontSize: "1.0rem" }}>Budujemy przyszłość komunikacji online</span><br />
              <span style={{ fontSize: "1.0rem" }}>Innowacyjne rozwiązania dla społeczności</span><br />
              <span style={{ fontSize: "1.0rem" }}>graczy i entuzjastów TeamSpeak 3</span><br /><br />
            </p>

            {/* Links Section */}
            <div>
              <p
                className="home-about-body fade-in"
                style={{
                  color: "#959599",
                  marginInline: "-10px",
                  marginRight: "50px",
                  marginInlineEnd: "10px",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: "-20px",
                    display: "block",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  WAŻNE LINKI
                </span><br />

                {/* Links */}
                <a href="/" style={hoveredLink === "home" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("home")} onMouseLeave={() => setHoveredLink(null)}>Strona główna</a><br />
                <a href="https://panel.galaxyspeak.pl/Login" style={hoveredLink === "panel" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("panel")} onMouseLeave={() => setHoveredLink(null)}>Panel</a><br />
                <a href="/faq" style={hoveredLink === "faq" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("faq")} onMouseLeave={() => setHoveredLink(null)}>FAQ</a><br />
                <a href="/user-stats" style={hoveredLink === "userstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("userstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Użytkowników</a><br />
                <a href="/guild-stats" style={hoveredLink === "guildstats" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("guildstats")} onMouseLeave={() => setHoveredLink(null)}>Statystyki Gildii</a><br />
                <a href="https://www.m4code.pl" style={hoveredLink === "creator" ? { ...linkStyle, ...hoverStyle } : linkStyle} onMouseEnter={() => setHoveredLink("creator")} onMouseLeave={() => setHoveredLink(null)}>Developer Strony</a><br />

              </p>
            </div>

            <div>
              <p
                className="home-about-body"
                style={{
                  color: "#959599",
                  marginRight: "-40px",
                  marginInline: "-10px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: "-20px",
                    display: "block",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  NOWI UŻYTKOWNICY
                </span>
                <br />

                {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                {/* Wyświetlanie użytkowników po zakończeniu ładowania */}
                {isLoaded && users.map((user) => (
                  <div key={user.id}>
                    <a
                      href="https://panel.galaxyspeak.pl/Login"
                      style={hoveredLink === user.nickname ? { ...linkStyle, ...hoverStyle } : linkStyle}
                      onMouseEnter={() => setHoveredLink(user.nickname)}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      {user.nickname} - {user.date}
                    </a>
                    <br />
                  </div>
                ))}
              </p>
            </div>

            <div>
              <p
                className="home-about-body"
                style={{
                  color: "#959599",
                  marginInline: "-10px",
                  textAlign: "left",
                  marginRight: "-200px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: "-20px",
                    display: "block",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  NOWE GILDIE/KLANY
                </span>
                <br />

                {/* Komunikat "Ładowanie..." przed wyświetleniem danych */}
                {!isLoaded && <div style={{ color: "gray" }}>Ładowanie...</div>}

                {isLoaded && guilds.slice(0, 4).map(guild => (  // Użycie slice(0, 5), aby ograniczyć do 5 elementów
                  <div key={guild.id}>
                    <a
                      href="https://panel.galaxyspeak.pl/Login"
                      style={hoveredLink === guild.name ? { ...linkStyle, ...hoverStyle } : linkStyle}
                      onMouseEnter={() => setHoveredLink(guild.name)}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      {guild.name} - {guild.date}
                    </a>
                    <br />
                  </div>
                ))}
              </p>
            </div>
          </div>
        </Col>
      </Container>

      {showScrollButton && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#6169C3",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            cursor: "pointer",
            zIndex: 1000,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <IoIosArrowUp size={30} />
        </button>
      )}
    </Container>
  );
}

export default About;
