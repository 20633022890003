import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Viewer from "./components/Serwer/Viewer";
import ListGuild from "./components/Serwer/ListGuild";
import User from "./components/Stats/User";
import SearchUser from "./components/Serwer/SearchUser";
import SearchGuild from "./components/Serwer/SearchGuild"
import Guild from "./components/Stats/Guild";
import Faq from "./components/Home/Faq";
import Footer from "./components/Footer";
import NotFound from './components/Error/NotFound';
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from './Context/AuthContext';
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/viewer" element={<Viewer />} />
            <Route path="/search-user" element={<SearchUser />} />
            <Route path="/search-guild" element={<SearchGuild />} />
            <Route path="/guild-list" element={<ListGuild />} />
            <Route path="/user-stats" element={<User />} />
            <Route path="/guild-stats" element={<Guild />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
