import React, { useState } from 'react';
import "../../../src/App.css";
import { FaTrophy, FaBolt } from "react-icons/fa"; // Ikony pucharu i pioruna

function HomeCards({ miejsce, gildia, user, czas, rank, spędzonyCzasText, style }) {
    const [isHovered, setIsHovered] = useState(false); // Stan dla hover

    // Kolor tła zależny od miejsca
    const backgroundColor = rank === 1 ? "#ffdd57" : "#5f64bc"; // Złoty dla #1, niebieski dla pozostałych

    // Kolor dla spędzonego czasu
    const czasColor = rank === 1 ? "#ffdd57" : "#5f64bc"; // Złoty dla #1, niebieski dla pozostałych

    // Kolor gildi
    const gildiaColor = "white"; // Kolor gildi, pozostaje niezmienny

    // Kolor tekstu użytkownika
    const userColor = rank === 1 ? "#ffdd57" : "#5f64bc"; // Złoty dla #1, szary dla pozostałych

    return (
        <div
            className="stats-card-view"
            style={{
                width: "200px",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                border: "1px solid white",
                borderRadius: "8px",
                backgroundColor: "#18222d",
                position: "relative",
                ...style // Zastosowanie zewnętrznego stylu
            }}
        >
            {/* Odznaka z numerem */}
            <div style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                display: "flex",
                alignItems: "center",
                backgroundColor: backgroundColor,
                color: "black",
                borderRadius: "8px",
                padding: "5px 10px",
                fontWeight: "bold",
                fontSize: "0.9em"
            }}>
                <FaTrophy style={{ marginRight: "5px" }} />
                #{rank}
            </div>

            {/* Etykieta: Gildia lub Użytkownik */}
            <h4 style={{
                color: gildia ? gildiaColor : userColor, // Kolor tekstu zależny od tego, czy jest gildia
                fontWeight: "800",
                fontSize: gildia ? "1.5em" : "2.2em", // Zwiększony rozmiar czcionki tylko dla użytkownika
                textAlign: "center",
                marginBottom: "10px",
                marginTop: "15px" // Dodajemy margines górny, aby przesunąć tekst niżej
            }}>
                {gildia ? `Gildia: ${gildia}` : user || "Nieokreślone"} {/* Wyświetl gildię lub nick */}
            </h4>

            {/* Box z miejscem */}
            <div style={{
                backgroundColor: rank === 1 ? "#11181e" : "#11181e", // Złote tło dla 1 miejsca
                color: "white",
                padding: "25px 70px",
                borderRadius: "15px",
                fontSize: "1.0em",
                fontWeight: "700",
                fontFamily: "Barlow, sans-serif",
                textAlign: "center"
            }}>
                <FaTrophy style={{ color: "gold", marginRight: "5px", marginTop: "-3px" }} />
                {miejsce}
            </div>

            {/* Spędzony czas na dole boxa */}
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#959599",
                fontSize: "0.9em",
                marginTop: "40px",
                width: "100%" // Umożliwiamy zajęcie pełnej szerokości
            }}>
                {/* Napis spędzonego czasu */}
                <span
                    style={{
                        marginLeft: "10px",
                        fontWeight: isHovered ? "bold" : "bold",
                        color: isHovered ? "white" : "#b1b3be",
                        transition: "color 0.5s"
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {spędzonyCzasText}
                </span>

                {/* Czas */}
                <span style={{ marginRight: "10px", color: czasColor, fontWeight: "bold" }}>
                    {czas} <FaBolt style={{ color: "gold" }} />
                </span>
            </div>
        </div>
    );
}

export default HomeCards;
