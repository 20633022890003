import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Check for token in sessionStorage on initial load
        const token = sessionStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
        setLoading(false);
    }, []);

    const login = (token) => {
        sessionStorage.setItem('authToken', token); // Store token in sessionStorage
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
        sessionStorage.removeItem('authToken'); // Remove token on logout
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
